import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

// Custom Components
import SoftBox from "components/SoftBox";

import SoftTypography from "components/SoftTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";

// Utilities

import { borderRadius, height, maxWidth, minHeight, spacing, textTransform, width } from "@mui/system";
import Swal from "sweetalert2";
import Chip from '@mui/material/Chip';

// Images
import image from "../../assets/FAQ.png";
import location from "assets/location (2) 1.svg";
import logo from "../../assets/indus.png";
import amex_logo from "../../assets/business-and-trade (6) 1.svg";
import Jd from "../../assets/Job Details.png";
import jobdesp from "../../assets/Job Description.png";
import { useMediaQuery } from "@mui/material";



import axios from "axios";
import { Grid } from "@mui/material";
import SoftButton from "components/SoftButton";



const Login = () => {
  const navigate = useNavigate();
  let { jobid, source } = useParams();
  const matches = useMediaQuery('(min-width:800px)');
  const transparent = false;
  const light = false;
  const [OTP, setOTP] = useState({
    mobileno: "",
    otp: "",
  });

  const [jdData, setJdData] = useState({
    designation: "",
  })

  const [isLoading, setIsLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState({
    email: "",
    password: "",
  });


  useEffect(() => {


    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?jobid=${jobid}`,)
      .then((res) => {

        console.log(res.data.JobPost_List.age, "omkar")
        setJdData(res.data.JobPost_List)


      })
      .catch((err) => {

      })
  }, []);

  { jdData, 'ooo' }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  window.addEventListener("popstate", onBackButtonEvent);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);

    var formData = new FormData();
    formData.append("email", loginEmail.email);
    formData.append("password", loginEmail.password);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL_AUTH}/login_page/`,
        // .post("https://career.guidence.middleware.uat.globalsapio.com/authentication/signin/", {
        {
          email: loginEmail.email,
          password: loginEmail.password,
        }
        // formData
      )
      .then((res) => {
        if (res.status === 200) {
          // const role = res.data.user_type_id;
          // sessionStorage.setItem("email", res.data["email"]);
          // sessionStorage.setItem("user_name", res.data["user_name"]);
          sessionStorage.setItem("role_type", res.data.role_type);
          sessionStorage.setItem("role_id", res.data.role_id);
          sessionStorage.setItem("user_id", res.data.user_id);
          sessionStorage.setItem("access_token", res.data.access);
          sessionStorage.setItem("refresh_token", res.data.refresh);
          sessionStorage.setItem("username", res.data.username);

          console.log("resData", res);

          navigate("/defaulter");

        } else {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#18838d",
            timer: 3000,
            title: "Invalid credentials",
            // text: "Invalid credentials",
          });
        } else {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        console.log(err);
        setIsLoading(false);
      });
    // }
  };

  const handleRedirect = (event) => {
    event.preventDefault(); // Prevents the default action of the anchor tag

    // Replace '/destination' with the URL you want to redirect to
    window.location.href = `${process.env.REACT_APP_FORM_URL}/jobs?cpid=&id=null&source=${source}&jobid=${jobid}&orgid=null`;
  };




  return (
    // <BasicLayout title="Welcome!" description="Select user type" image={curved6}>
    <>
      <PageLayout>
        <SoftBox
          sx={{
            minHeight: "100vh",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <SoftBox
            sx={
              matches ? {
                height: '100%',
                background: '#FFFFFF',
                borderRadius: "8px",
                width: '800px',
                m: 2,
                p: 2
              } :
                {
                  height: '100%',
                  background: '#FFFFFF',
                  borderRadius: "8px",
                  maxWidth: '800px',
                  m: 2,
                  p: 2
                }}
          >
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={3} xs={3}>
                <SoftBox sx={{ p: 2, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                  <img src={logo} alt="logo" width='75px' height='72px' style={{ marginTop: "8px" }} />
                </SoftBox>
              </Grid>

              <Grid item lg={10} md={9} sm={9} xs={9}>
                <SoftBox sx={{ p: 2, width:'100%' }}>
                  <SoftTypography sx={{ fontSize: "24px", color: "#2F55D0", fontWeight: "700", mb: 1 }}>
                    {jdData.designation ? jdData.designation : '-'}
                  </SoftTypography>

                  <SoftBox sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
                    <img src={amex_logo} width="18px" height="18px" style={{ marginRight: "8px" }} />

                    <SoftTypography sx={{ fontSize: "14px", color: "#848383", fontWeight: "500" }}>
                      Organization Name: <span style={{ fontWeight: "bold", fontSize: "14px", color: "black", width: "70px" }}>{jdData.org_name ? jdData.org_name : "-"}</span>
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
                    <img src={location} width="18px" height="18px" style={{ marginRight: "8px" }} />

                    <SoftTypography sx={{ fontSize: "14px", color: "#848383", fontWeight: "500" }}>
                      Location: <span style={{ fontWeight: "bold", fontSize: "14px", color: "black" }}> {jdData.district ? jdData.district : "-"}</span>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>

            <SoftBox sx={{ ml: 3, mr: 3, m: 2 }}>
              <SoftTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                Job Details
              </SoftTypography>
              <hr />

              <SoftBox sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                <Chip label={`Job Type: ${jdData.job_type ? jdData.job_type : "-"}`} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                <Chip label={`Salary : ${jdData.salary ? jdData.salary : "-"}`} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                <Chip label={`Experience :${jdData.experience ? jdData.experience : "-"}`} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                <Chip label={`No. of Opening : ${jdData.applicantct ? jdData.applicantct : "-"}`} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                <Chip label={`Date of Posting : ${jdData.jobpostdt ? jdData.jobpostdt : '-'} `} variant="outlined" sx={{ mr: 1, mb: 1 }} />
              </SoftBox>
            </SoftBox>

            <SoftBox sx={{ ml: 3, mr: 3, m: 2 }}>
              <SoftTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                Job Description
              </SoftTypography>
              <hr />

              <SoftTypography dangerouslySetInnerHTML={{ __html: jdData.jobdesc }} sx={{ fontSize: "16px", color: '#000000' }} />
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" alignItems="center" margin="8px">
              <a onClick={handleRedirect} href={`${process.env.REACT_APP_FORM_URL}/jobs?cpid=&id=null&source=null&jobid=${jobid}&orgid=null`}>
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "120px",
                    height: "40px",
                    borderRadius: "6px",
                    background: "#2D60FF",
                    marginTop: "2rem",
                    cursor: "pointer"
                  }}
                >
                  <SoftTypography sx={{ color: "#fff", fontSize: "15px", fontWeight: "700" }} >
                    Apply Now
                  </SoftTypography>
                </SoftBox>
              </a>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </PageLayout>
    </>
  );
};

export default Login;
